<template>
  <div>
    <div class="tab">
      <ul class="d-flex">
        <li
          class="tab-item d-flex justify-center cursor"
          @click="tabIndex(0)"
          :class="{ tab_active: type == 0 }"
        >
          全部
        </li>
        <li
          class="tab-item d-flex justify-center cursor"
          @click="tabIndex(1)"
          :class="{ tab_active: type == 1 }"
        >
          待支付
        </li>
        <li
          class="tab-item d-flex justify-center cursor"
          @click="tabIndex(2)"
          :class="{ tab_active: type == 2 }"
        >
          已购买
        </li>
      </ul>
    </div>
    <div class="order-box">
      <div class="order-title d-flex align-center">
        <div class="title-l">订单详情</div>
        <div class="title-c">金额/元</div>
        <div class="title-r">订单状态</div>
      </div>
      <ul class="order" v-if="list">
        <li class="order-item" v-for="(item, index) in list" :key="index">
          <div class="order-top d-flex align-center">
            <div class="time">{{ item.createTime }}</div>
            <div class="num">
              订单号：<span>{{ item.orderNum }}</span>
            </div>
            <!-- <div
              class="btn cursor"
              @click="deleteBtn(item.id)"
              v-if="item.statu == 0"
            >
              删除订单
            </div> -->
          </div>
          <div class="order-content d-flex">
            <div class="item-detail d-flex">
              <div class="item-img">
                <img :src="item.packageImg" alt="" />
              </div>
              <div class="item-r d-flex flex-column justify-between">
                <div class="item-name">
                  {{ item.packageName }}
                </div>
                <div class="item-tea">授课老师：刘晓燕</div>
                <div class="item-alltime">总课时：{{ item.lessonCount }}</div>
                <div class="item-time">有效期：{{ item.packageEndTime }}</div>
              </div>
            </div>
            <div
              v-if="item.newStatus == 2"
              class="item-pic d-flex align-center justify-center"
            >
              ¥ {{ item.payPrice }}
            </div>
            <div
              class="item-pics d-flex align-center justify-center"
              v-if="item.newStatus != 2"
            >
              ¥ {{ item.payPrice }}
            </div>
            <div
              v-if="item.newStatus == 2"
              class="item-active d-flex align-center justify-center"
            >
              待付款
            </div>
            <div
              v-if="item.newStatus == 0"
              class="item-actives d-flex align-center justify-center"
            >
              未支付
            </div>
            <div
              v-if="item.newStatus == 1"
              class="item-actives d-flex align-center justify-center"
            >
              已支付
            </div>
            <!-- <div
              v-if="item.newStatus == 3"
              class="item-actives d-flex align-center justify-center"
            >
              已取消
            </div> -->
            <div
              class="item-btn d-flex align-center justify-center flex-column"
            >
              <div
                v-if="item.newStatus == 2"
                class="btn-pay d-flex align-center justify-center cursor"
                @click="goOrder(item)"
              >
                去支付
              </div>
              <div
                v-if="item.statu == 1 && item.isSign == 0"
                class="btn d-flex align-center justify-center cursor"
                @click="goHetong(item.packageId)"
              >
                查看合同
              </div>
              <div
                v-if="item.statu == 1 && item.isSign == 0"
                class="btn d-flex align-center justify-center cursor"
                @click="goQianzi(item.packageId)"
                style="margin-top: 10px"
              >
                签字
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div
        v-if="!list"
        class="kong d-flex align-center flex-column justify-center"
      >
        <img class="kong-img" src="../../assets/img/kong.png" alt="" />
        <div class="kong-txt">暂无内容</div>
      </div>
      <v-pagination
        v-show="total > display"
        :total="total"
        :current-page="current"
        :display="display"
        @pagechange="pagechange"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import pagination from "@/components/pagination";
import config from "../../utils/config";
export default {
  data() {
    return {
      total: 0, // 记录总条数
      display: 4, // 每页显示条数
      current: 1, // 当前的页数
      type: 0,
      list: ""
    };
  },
  components: {
    "v-pagination": pagination
  },
  created() {
    this.getDetails();
  },
  mounted() {
    this.getDetails();
  },
  methods: {
    tabIndex(id) {
      if (this.type != id) {
        this.type = id;
        this.getDetails();
      }
    },
    deleteBtn(id) {
      let _this = this;
      _this
        .$confirm("确认删除订单？")
        .then(_ => {
          _this.$api
            .delOrder({
              orderId: id
            })
            .then(res => {
              console.log(res);
              _this.$message({
                message: res.message,
                type: "success"
              });
              _this.getDetails();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(_ => { });
    },
    pagechange(currentPage) {
      console.log(currentPage); //该参数就是当前点击的页码数
      // ajax请求, 向后台发送 currentPage, 来获取对应的数据
      this.current = currentPage;
      this.getDetails();
      const currentY =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollAnimation(currentY, 0);
    },
    async getDetails(params) {
      let _this = this;
      let data = {
        orderType: _this.type,
        pageNum: _this.current,
        pageSize: _this.display
      }
      const res = await this.$ajaxRequest('post', 'myOrders', data)
      console.log(res.data);
      _this.list = res.data.list;
      _this.total = res.data.totalRows;
    },
    // getDetails() {
    //   let _this = this;
    //   _this.$api
    //     .myOrders({
    //       type: _this.type,
    //       start: _this.current - 1,
    //       limit: _this.display
    //     })
    //     .then(res => {
    //       console.log(res);
    //       _this.list = res.data;
    //       _this.total = res.count;
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // },
    scrollAnimation(currentY, targetY) {
      // 获取当前位置方法
      // 计算需要移动的距离
      let needScrollTop = targetY - currentY;
      let _currentY = currentY;
      setTimeout(() => {
        // 一次调用滑动帧数，每次调用会不一样
        const dist = Math.ceil(needScrollTop / 10);
        _currentY += dist;
        window.scrollTo(_currentY, currentY);
        // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
        if (needScrollTop > 10 || needScrollTop < -10) {
          this.scrollAnimation(_currentY, targetY);
        } else {
          window.scrollTo(_currentY, targetY);
        }
      }, 1);
    },
    // 查看合同
    // saveMsg
    async goHetong(id) {
      let data = id
      const res = await this.$ajaxRequest('get', 'saveMsg', '', data)
      console.log(res)
      window.localStorage.setItem("id", id);
      location.href = res.data.htmlUrl;
    },
    //签字
    goQianzi(id) {
      console.log(id);
      let _this = this;
      let htId = window.localStorage.getItem("id");
      if (htId == id) {
        this.$router.push({
          path: `sketchpad`
        });
      } else {
        _this
          .$confirm("请您查看合同后签字", "签字", {
            showCancelButton: false,
            showClose: false,
            confirmButtonText: "确定",
            type: "warning"
          })
          .then(res => { });
      }
    },
    goOrder(item) {
      console.log(item);
      this.$router.push({
        path: `orderPay`,
        query: {
          id: item.id
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.kong {
  margin-top: 50px;
  .kong-img {
    height: 210px;
    width: 280px;
  }
  .kong-txt {
    font-size: 20px;
    color: #999999;
    margin-top: 30px;
  }
}
.tab_active {
  border-color: #5f2eff !important;
  color: #5f2eff !important;
}
.tab {
  width: 1000px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  border-radius: 6px;
  padding-top: 36px;
  box-sizing: border-box;
  .tab-item {
    width: 120px;
    padding-bottom: 11px;
    box-sizing: border-box;
    border-bottom: 2px solid transparent;
    margin-left: 15px;
    font-size: 20px;
    color: #666666;
  }
}
.order-box {
  width: 1000px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  border-radius: 6px;
  margin-top: 20px;
  padding: 32px 36px 62px 36px;
  box-sizing: border-box;
  .order-title {
    width: 928px;
    height: 40px;
    background: #f7f9fa;
    border-radius: 4px;
    .title-l {
      width: 524px;
      text-align: center;
      font-size: 14px;
      color: #666666;
    }
    .title-c {
      width: 115px;
      text-align: center;
      font-size: 14px;
      color: #666666;
    }
    .title-r {
      width: 90px;
      text-align: center;
      font-size: 14px;
      color: #666666;
    }
  }
}
.order {
  .order-item {
    width: 928px;
    background: #ffffff;
    border-bottom: 1px solid #eeeeee;
    box-shadow: 0px 0px 6px 0px rgba(191, 191, 191, 0.18);
    border-radius: 4px;
    margin-top: 20px;
    .order-top {
      width: 928px;
      height: 50px;
      background: #f7f9fa;
      padding: 0px 26px;
      box-sizing: border-box;
      .time {
        font-size: 14px;
        color: #999999;
      }
      .num {
        color: #999999;
        font-size: 14px;
        margin-left: 41px;
        span {
          color: #444444;
          font-size: 14px;
        }
      }
      .btn {
        font-size: 14px;
        color: #999999;
        margin-left: auto;
      }
    }
    .order-content {
      background-color: #fff;
      .item-detail {
        width: 553px;
        border-left: 1px solid#EEEEEE;
        padding: 14px 0 14px 26px;
        box-sizing: border-box;
        .item-img {
          width: 140px;
          height: 140px;
          background: #eeeeee;
          border-radius: 6px;
          overflow: hidden;
        }
        .item-r {
          margin-left: 15px;
          .item-name {
            font-size: 18px;
            font-weight: bold;
            color: #444444;
          }
          .item-tea {
            font-size: 14px;
            color: #666666;
          }
          .item-alltime {
            font-size: 14px;
            color: #666666;
          }
          .item-time {
            font-size: 14px;
            color: #999999;
          }
        }
      }
      .item-pic {
        width: 115px;
        border-left: 1px solid#EEEEEE;
        font-size: 22px;
        font-weight: bold;
        color: #f53444;
      }
      .item-pics {
        width: 115px;
        border-left: 1px solid#EEEEEE;
        font-size: 22px;
        font-weight: bold;
        color: #666666;
      }
      .item-active {
        width: 90px;
        border-left: 1px solid#EEEEEE;
        font-size: 16px;
        color: #f53444;
      }
      .item-actives {
        width: 90px;
        border-left: 1px solid#EEEEEE;
        font-size: 16px;
        color: #333333;
      }
      .item-btn {
        width: 203px;
        border-left: 1px solid#EEEEEE;
        border-right: 1px solid#EEEEEE;
        .btn {
          width: 102px;
          height: 34px;
          // background: linear-gradient(-17deg, #ed2e32 1%, #f8a927 100%);
          background-image: linear-gradient(176deg, #406bff 0%, #5f2eff 100%),
            linear-gradient(#f36470, #f36470);
          border-radius: 17px;
          font-size: 16px;
          font-weight: normal;
          color: #ffffff;
        }
        .btn-pay {
          width: 102px;
          height: 34px;
          // background: linear-gradient(-17deg, #ed2e32 1%, #f8a927 100%);
          background: linear-gradient(90deg, #ffba13 0%, #fb283a 100%);
          border-radius: 17px;
          font-size: 16px;
          font-weight: normal;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
